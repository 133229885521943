import React from "react";
import "./App.css";
import { CalculatorPage } from "./pages";

//...
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3e99c90b28665538d193d6dc5a01eb0a@o4508126790942720.ingest.de.sentry.io/4508126793433168",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  return (
    <div className="App">
      <CalculatorPage />
    </div>
  );
};

export default App;
